import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import {
    format,
    getPayloadObject,
    getTelemetryAttributes,
    ITelemetryContent,
    onTelemetryClick
} from '@msdyn365-commerce-modules/utilities';
import {
    IComponent,
    IComponentProps,
    ICoreContext,
    IGridSettings,
    IImageData,
    IImageSettings,
    Image,
    IRequestContext,
    msdyn365Commerce,
    IImageProps
} from '@msdyn365-commerce/core';
import { ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { PriceComponent } from '@msdyn365-commerce/components';
import Bazzarvoice from '../../../../modules/bazzarvoice-widget/components/Bazzarvoice';
import { widgetType } from '../../../../modules/bazzarvoice-widget/bazzarvoice-widget.props.autogenerated';
import { ProductStockStatus } from './ProductStockStatus';
import {
    findAllAttributes,
    findAttribute,
    findAttributeValue,
    getProductBadgeUrl,
    getProductStockStatus
} from '../../../../common/utilities/productListExtensionHelpers';
import { translate } from '../../../../common/utilities/localisation/translationUtilities';

const DefaultFallbackImage = 'https://henrys.com/?fileName=placeholder.png';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    allowBack?: boolean;
    telemetryContent?: ITelemetryContent;
    quickViewButton?: React.ReactNode;
}

export interface IProductComponent extends IComponent<IProductComponentProps> {}

const PriceComponentActions = {};

const ProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    allowBack,
    typeName,
    id,
    telemetryContent,
    quickViewButton
}) => {
    const product = data.product;
    if (!product) {
        return null;
    }

    const [shouldHidePrice, setShouldHidePrice] = React.useState<boolean | null>(null);
    let productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, undefined);
    if (allowBack) {
        productUrl = updateProductUrl(productUrl, context);
    }

    // Construct telemetry attribute to render
    const payLoad = getPayloadObject('click', telemetryContent!, '', product.RecordId.toString());

    const attribute = getTelemetryAttributes(telemetryContent!, payLoad);

    const baseUrl = context.request.apiSettings.baseImageUrl;
    const locale = context.request.locale;
    const productBadgeStatus = getProductBadgeUrl(product, locale, baseUrl, '_PLP');
    React.useEffect(() => {
        const showPriceInCartAttributeArray = findAllAttributes(product.AttributeValues, 'See Price In Cart');
        if (showPriceInCartAttributeArray && showPriceInCartAttributeArray.length) {
            for (let i = 0; i < showPriceInCartAttributeArray.length; i++) {
                // have to find all attributes for this and loop through
                const attributeValue = findAttributeValue(showPriceInCartAttributeArray[i]);
                if (typeof attributeValue === 'boolean' && attributeValue) {
                    setShouldHidePrice(true);
                    break;
                }
                if (shouldHidePrice === null) {
                    setShouldHidePrice(false);
                }
            }
        } else {
            setShouldHidePrice(false);
        }
    }, []);

    return (
        <>
            <a
                href={productUrl}
                onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
                aria-label={renderLabel(
                    product.Name,
                    context.cultureFormatter.formatCurrency(product.Price),
                    product.AverageRating,
                    ratingAriaLabel
                )}
                className='msc-product'
                {...attribute}
            >
                <div className='msc-product__image'>
                    {renderProductPlacementImage(
                        imageSettings,
                        context.request.gridSettings,
                        product.PrimaryImageUrl,
                        product.Name,
                        context.actionContext.requestContext
                    )}
                    {productBadgeStatus && (
                        <img
                            src={productBadgeStatus.url}
                            alt={productBadgeStatus.badgeName}
                            className={'msc-product__badge msc-product__badge--list'}
                        />
                    )}
                </div>
                {_renderProductSkuMFR(product)}
                <div className='msc-product__details'>
                    <div className='msc-product__title'>{product.Name}</div>
                    {renderRating(product)}
                    {shouldHidePrice === null
                        ? null
                        : renderPrice(
                              context,
                              typeName,
                              id,
                              shouldHidePrice ? translate(locale, 'product-list.see-price-in-cart', 'See Price In Cart') : null,
                              product.BasePrice,
                              product.Price,
                              savingsText,
                              freePriceText,
                              originalPriceText
                          )}
                    {renderDescription(product.Description)}
                    {product.ExtensionProperties?.length ? (
                        <ProductStockStatus
                            availabilityStatus={getProductStockStatus(product.ExtensionProperties)}
                            locale={locale}
                            hideStatus={context.app.config.hideStockStatus}
                        />
                    ) : null}
                </div>
            </a>
            {quickViewButton && renderQuickView(quickViewButton, product.RecordId)}
        </>
    );
};

function _renderProductSkuMFR(product: ProductSearchResult): JSX.Element {
    const mfrAttr = findAttribute(product.AttributeValues, 'MFR');
    let mfrValue;
    if (mfrAttr) {
        mfrValue = findAttributeValue(mfrAttr);
    }
    return (
        <p>
            SKU: {product.ItemId} {mfrValue ? ` MFR Code: ${mfrValue}` : null}
        </p>
    );
}

function renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
    name = name || '';
    price = price || '';
    return `${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`;
}

function renderDescription(description?: string): JSX.Element | null {
    return <p className='msc-product__text'>{description}</p>;
}

function renderQuickView(quickview: React.ReactNode, item?: number): JSX.Element | undefined {
    if (quickview === null) {
        return undefined;
    }
    return React.cloneElement(quickview as React.ReactElement, { selectedProductId: item });
}

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
    if (rating && ratingAriaLabel) {
        const roundedRating = rating.toFixed(2);
        return format(ratingAriaLabel || '', roundedRating, '5');
    }
    return '';
}

function updateProductUrl(productUrl: string, context: ICoreContext): string {
    const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
    const queryString = 'back=true';
    if (srcUrl.search) {
        srcUrl.search += `&${queryString}`;
    } else {
        srcUrl.search += queryString;
    }

    const updatedUrl = new URL(srcUrl.href);
    return updatedUrl.pathname + srcUrl.search;
}

function renderRating(product?: ProductSearchResult): JSX.Element | null {
    if (!product) {
        return null;
    }

    return <Bazzarvoice itemId={product?.ItemId} widgetType={widgetType.inline_rating} disableSeo />;
}

function renderPrice(
    context: ICoreContext,
    typeName: string,
    id: string,
    seePriceInCart?: string | null,
    basePrice?: number,
    adjustedPrice?: number,
    savingsText?: string,
    freePriceText?: string,
    originalPriceText?: string,
    currentPriceText?: string
): JSX.Element | null {
    const price: ProductPrice = {
        BasePrice: basePrice,
        AdjustedPrice: adjustedPrice,
        CustomerContextualPrice: adjustedPrice
    };

    if (seePriceInCart) {
        return <p className={'hide-price'}>{seePriceInCart}</p>;
    }

    return (
        <PriceComponent
            context={context}
            id={id}
            typeName={typeName}
            data={{ price }}
            savingsText={savingsText}
            freePriceText={freePriceText}
            originalPriceText={originalPriceText}
        />
    );
}

/**
 * Gets the react component for product rating.
 * @param  productCardimageSettings - Module image settings for product card.
 * @param  gridSettings - Grid settings defined in theme.
 * @param  imageUrl - Image url.
 * @param fallbackImageUrl - Fallback url for imge.
 * @param  altText - Image Alt text.
 * @param  requestContext - Request context using the component.
 * @returns React component for product image.
 */
function renderProductPlacementImage(
    imageSettings?: IImageSettings,
    gridSettings?: IGridSettings,
    imageUrl?: string,
    altText?: string,
    requestContext?: IRequestContext
): JSX.Element | null {
    if (!imageUrl || !gridSettings || !imageSettings) {
        return null;
    }

    const emptyPlaceHolderImage: string | undefined = requestContext?.app.config.placeholderImageName;
    let fallbackImageSource = emptyPlaceHolderImage ?? DefaultFallbackImage;

    const image: IImageData = {
        src: imageUrl,
        altText: altText ? altText : '',
        fallBackSrc: fallbackImageSource
    };
    const imageProps: IImageProps = {
        gridSettings,
        imageSettings: {
            ...imageSettings,
            cropFocalRegion: true
        }
    };

    return (
        <Image
            {...image}
            {...imageProps}
            loadFailureBehavior='empty'
            requestContext={requestContext}
            // fallBackSrc={'placeholder'}
            bypassHideOnFailure
        />
    );
}

export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponentOverride<
    IProductComponent
>('Product', { component: ProductCard, ...PriceComponentActions });

export default ProductComponent;
