import React from 'react';
import { ProductStockStatusEnum } from '../../../../common/utilities/productListExtensionHelpers';
import { translate } from '../../../../common/utilities/localisation/translationUtilities';

interface IProductStockStatusProps {
    availabilityStatus: ProductStockStatusEnum;
    locale: string;
    hideStatus: boolean;
}

export const ProductStockStatus: React.FC<IProductStockStatusProps> = ({ availabilityStatus, locale, hideStatus }) => {
    switch (availabilityStatus) {
        case ProductStockStatusEnum.ONLINE:
            return hideStatus ? null : (
                <p>
                    <span className={'fa fa--filled fa-check-circle'}>&#xf058;</span>
                    {' '}
                    {translate(locale, 'product-list.in-stock', 'In Stock Online')}
                </p>
            );
        case ProductStockStatusEnum.IN_STORE:
            return (
                <>
                    {!hideStatus && <p>
                        <span className={'fa fa--filled fa-times-circle'}>&#xf057;</span>
                        {' '}
                        {translate(locale, 'product-list.out-of-stock', 'Out of Stock online')}
                    </p>}
                    <p>
                        {translate(locale, 'product-list.available-in-store', 'Available in select Stores')}
                    </p>
                </>
            );
        case ProductStockStatusEnum.OUT_OF_STOCK:
        default:
            return hideStatus ? null : (
                <>
                    <p>
                        <span className={'fa fa--filled fa-times-circle'}>&#xf057;</span>
                        {' '}
                        {translate(locale, 'product-list.out-of-stock', 'Out of Stock online')}
                    </p>
                </>
            );
    }
};
