import translations from './translation.json';

export const translate = (locale: string, translationPath: string, fallback: string): string => {
    try {
        let translation = translations[(locale || '').toLowerCase()];
        if (!translation) {
            translation = translations['en-us'];
        }
        const splitString = translationPath.split('.');
        for (let i = 0; i < splitString.length; i++) {
            translation = translation[splitString[i]];
            if (!translation) {
                break;
            }
        }
        if (translation) {
            return translation;
        } else {
            console.warn(`Translation path [${translationPath}] for [${locale}] missing`);
            return translation || fallback;
        }
    } catch (e) {
        console.warn('Translation key missing');
        return fallback;
    }
};
